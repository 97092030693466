import axios from "axios";

export const getConcurrentPlayersByGameId = async (id: string) => {
  try {
    const { data } = await axios.get(
      `https://fsid3q30ik.execute-api.eu-north-1.amazonaws.com/dev/games/${id}/concurrentPlayers`
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};
