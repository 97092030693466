import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import clsx from "clsx";
import { coronaStatus } from "../../api/data/corona";
import {
  Grid,
  Paper,
  Select,
  CssBaseline,
  Container,
  Drawer,
  List,
  IconButton,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Button
} from "@material-ui/core";
import TableChart from "@material-ui/icons/TableChart";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CasesSheet from "./CasesSheet";
import CasesTimelineGraph from "./CasesTimelineGraph";
import DailyGraph from "./DailyGraph";
import CircleDiagram from "./CircleDiagram";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import TimelineIcon from "@material-ui/icons/Timeline";
import WorldMap from "./WorldMap";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  //appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: isBrowser ? theme.spacing(3) : theme.spacing(1),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: isBrowser ? theme.spacing(3) : theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 320
  },
  fixedHeight: {
    height: 900
  },
  smallFixedHeight: {
    height: isMobile ? 350 : 500,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  miniFixedHeight: {
    height: 55,
    marginTop: theme.spacing(3)
  },
  mapFixedHeight: {
    height: isMobile ? 250 : 500,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(3)
    //maxWidth: "500px"
  },
  buttonWidth: {
    width: "123px"
  },
  formGroup: {
    display: "flex",
    overflow: "auto",
    alignContent: "center",
    justifyContent: "center"
  }
}));

const vals = ["Market Cap", "EV", "EV/EBIT", "Adjusted EV/EBIT", "P/E"];

export default function Corona() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { corona } = useSelector((state: any) => state);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [country, setCountry] = React.useState("World");
  const [graphType, setGraphType] = React.useState("TOTAL");
  const handleGraphType = () =>
    graphType === "TOTAL" ? setGraphType("PER_DAY") : setGraphType("TOTAL");

  /*  const [open, setOpen] = React.useState(true);
  const handleDrawerOpenClose = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const [selectedStock, selectStock] = React.useState("TTWO");
  const handleSelectedStock = (id: string) => {
    selectStock(id);
  };

  const [view, selectView] = React.useState("DASHBOARD");

  const [stockFilter, setStockFilter] = React.useState("");
  const handleStockFilter = (value: any) => {
    setStockFilter(value);
  }; */

  const [sortId, setSortId] = React.useState({ id: 1, direction: "desc" });
  const handleSort = (id: any) => {
    if (id !== sortId.id) {
      let sortedStuff;
      setSortId({
        id,
        direction: sortId.direction === "desc" ? "desc" : "asc"
      });

      if (sortId.direction === "asc") {
        if (id === 1) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.totalCases - r.totalCases
          );
        } else if (id === 2) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.totalDeaths - r.totalDeaths
          );
        } else if (id === 3) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.activeCases - r.activeCases
          );
        } else if (id === 4) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.totalRecovered - r.totalRecovered
          );
        } else if (id === 5) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.seriousCritical - r.seriousCritical
          );
        } else if (id === 0) {
          sortedStuff = Object.values(corona.status).sort((l: any, r: any) =>
            l.countryName < r.countryName ? -1 : 1
          );
        }
      } else {
        if (id === 1) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.totalCases - l.totalCases
          );
        } else if (id === 2) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.totalDeaths - l.totalDeaths
          );
        } else if (id === 3) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.activeCases - l.activeCases
          );
        } else if (id === 4) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.totalRecovered - l.totalRecovered
          );
        } else if (id === 5) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.seriousCritical - l.seriousCritical
          );
        } else if (id === 0) {
          sortedStuff = Object.values(corona.status).sort((l: any, r: any) =>
            l.countryName < r.countryName ? 1 : -1
          );
        }
      }
      dispatch(actions.setCoronaStatus(sortedStuff));
    } else {
      setSortId({
        id,
        direction: sortId.direction === "desc" ? "asc" : "desc"
      });
      let sortedStuff;
      if (sortId.direction === "desc") {
        if (id === 1) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.totalCases - r.totalCases
          );
        } else if (id === 2) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.totalDeaths - r.totalDeaths
          );
        } else if (id === 3) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.activeCases - r.activeCases
          );
        } else if (id === 4) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.totalRecovered - r.totalRecovered
          );
        } else if (id === 5) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => l.seriousCritical - r.seriousCritical
          );
        } else if (id === 0) {
          sortedStuff = Object.values(corona.status).sort((l: any, r: any) =>
            l.countryName < r.countryName ? -1 : 1
          );
        }
      } else {
        if (id === 1) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.totalCases - l.totalCases
          );
        } else if (id === 2) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.totalDeaths - l.totalDeaths
          );
        } else if (id === 3) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.activeCases - l.activeCases
          );
        } else if (id === 4) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.totalRecovered - l.totalRecovered
          );
        } else if (id === 5) {
          sortedStuff = Object.values(corona.status).sort(
            (l: any, r: any) => r.seriousCritical - l.seriousCritical
          );
        } else if (id === 0) {
          sortedStuff = Object.values(corona.status).sort((l: any, r: any) =>
            l.countryName < r.countryName ? 1 : -1
          );
        }
      }
      dispatch(actions.setCoronaStatus(sortedStuff));
    }
  };

  useEffect(() => {
    dispatch(actions.initCoronaStatus(coronaStatus));
    dispatch(actions.getGlobalCoronaTimeline());
  }, []);
  const smallFixedHeightPaper = clsx(classes.paper, classes.smallFixedHeight);
  const miniFixedHeightPaper = clsx(classes.paper, classes.miniFixedHeight);

  const mapFixedHeightPaper = clsx(classes.paper, classes.mapFixedHeight);
  return (
    <div className={classes.root}>
      <CssBaseline />

      {/*       <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerOpenClose}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem button onClick={() => selectView("DASHBOARD")}>
              <ListItemIcon>
                <TableChart />
              </ListItemIcon>
              <ListItemText primary="Current Status" />
            </ListItem>
            <ListItem button onClick={() => selectView("DEVELOPMENT")}>
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary="Development" />
            </ListItem>
          </div>
        </List>
        <Divider />
        <List>
          <div>
            <ListItem>
              <TextField
                id="standard-basic"
                label="Countries"
                onChange={event => handleStockFilter(event.target.value)}
              />
            </ListItem>
            {countryCodes.map(s => {
              if (s.toLowerCase().includes(stockFilter.toLowerCase())) {
                return (
                  <ListItem
                    button
                    selected={selectedStock === s}
                    onClick={() => {
                      alert("hej");
                    }}
                  >
                    <ListItemText primary={s} />
                  </ListItem>
                );
              }
            })}
          </div>
        </List>
      </Drawer> */}
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={smallFixedHeightPaper}>
          {/* <Grid container spacing={8} alignItems="center">
            <Grid item lg={5}>
              <Button
                className={classes.buttonWidth}
                variant="contained"
                onClick={() => setGraphType("TOTAL")}
              >
                In total
              </Button>
            </Grid>
            <Grid item lg={5}>
              <Button
                className={classes.buttonWidth}
                variant="contained"
                onClick={() => setGraphType("PER_DAY")}
              >
                Per day
              </Button>
            </Grid>
          </Grid> */}
          <Typography gutterBottom variant="h6" component="h2">
            Infected in {country}:{" "}
            {country === "World"
              ? corona.timelines
                ? corona.timelines.global[corona.timelines.global.length - 1]
                    .totalCases
                : 0
              : corona.status
              ? corona.status.find((c: any) => c.countryName === country)
                  .totalCases
              : 0}
          </Typography>
          {corona.timelines &&
          corona.timelines.countries &&
          corona.timelines.global ? (
            country === "World" ? (
              graphType === "TOTAL" ? (
                <CasesTimelineGraph timeline={corona.timelines.global} />
              ) : (
                <DailyGraph timeline={corona.timelines.global} />
              )
            ) : graphType === "TOTAL" ? (
              <CasesTimelineGraph
                timeline={
                  corona.timelines.countries.find(
                    (c: any) => c.countryName === country
                  ).timeline
                }
              />
            ) : (
              <DailyGraph
                timeline={
                  corona.timelines.countries.find(
                    (c: any) => c.countryName === country
                  ).timeline
                }
              />
            )
          ) : (
            undefined
          )}
          <div>
            Updated{" "}
            {corona.status
              ? new Date(corona.status[0].timeStamp).toLocaleTimeString()
              : undefined}
            {"  "}{" "}
            {!isMobile ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleGraphType()}
              >
                Show {graphType === "TOTAL" ? "1-day change" : "all"}
              </Button>
            ) : (
              undefined
            )}
          </div>
          {isMobile ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleGraphType()}
            >
              Show {graphType === "TOTAL" ? "1-day change" : "all"}
            </Button>
          ) : (
            undefined
          )}
        </Paper>

        <Paper className={mapFixedHeightPaper}>
          <WorldMap coronaStatus={corona.status} setCountry={setCountry} />
        </Paper>

        <Grid container spacing={8} alignItems="center">
          <Grid item xs={12}>
            {corona ? (
              <Paper className={fixedHeightPaper}>
                <CasesSheet
                  {...{
                    coronaStatus: corona.status,
                    sortId,
                    handleSort,
                    setCountry
                  }}
                />
              </Paper>
            ) : (
              undefined
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
