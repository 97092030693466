import * as types from "../actions/actionTypes";

export const pricesReducer = (
  state: any = { prices: null },
  action: any
): any => {
  switch (action.type) {
    case types.GET_PRICES_SUCCESS: {
      return { ...state, prices: action.prices };
    }
    default:
      return state;
  }
};
