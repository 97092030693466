import { getStockPricesById } from "./../../api/StockPrices";
import { Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Action } from "typesafe-actions";
import { RootState } from "../reducers";
import { GET_PRICES, GET_PRICES_SUCCESS } from "../actions/actionTypes";

const pricesEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    ofType(GET_PRICES),
    mergeMap((action: any) => {
      return from(
        getStockPricesById(action.id).then(async pricesResponse => {
          return {
            type: GET_PRICES_SUCCESS,
            prices: pricesResponse
          };
        })
      );
    })
  );

export default [pricesEpic];
