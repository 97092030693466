import { Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Action } from "typesafe-actions";
import { RootState } from "../reducers";
import { GET_GAME, GET_GAME_SUCCESS } from "../actions/actionTypes";
import { getConcurrentPlayersByGameId } from "../../api/ConcurrentPlayers";

const gameEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    ofType(GET_GAME),
    mergeMap((action: any) => {
      return from(
        getConcurrentPlayersByGameId(action.id).then(async gameResponse => {
          return {
            type: GET_GAME_SUCCESS,
            game: gameResponse
          };
        })
      );
    })
  );

export default [gameEpic];
