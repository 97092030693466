import React from "react";
import "./Footer.css";

const Footer: React.FC = () => (
  <div className="Footer">
    <p>© Gustav Kullberg, {new Date().toISOString().split("-")[0]}</p>
  </div>
);

export default Footer;
