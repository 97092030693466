import * as types from "../actions/actionTypes";

export const coronaReducer = (state: any = "", action: any): any => {
  switch (action.type) {
    case types.GET_CORONA_STATUS_SUCCESS: {
      return { ...state, ...{ status: action.coronaStatus } };
    }
    case types.SET_CORONA_STATUS: {
      return { ...state, ...{ status: action.coronaStatus } };
    }
    case types.GET_GLOBAL_CORONA_TIMELINE_SUCCESS: {
      return {
        ...state,
        ...{
          timelines: {
            global: action.globalTimeline,
            countries: action.countriesTimelines
          }
        }
      };
    }
    default:
      return state;
  }
};
