import React from "react";
import CvComponent from "./cvComponent/CvComponent";
import MeComponent from "./meComponent/MeComponent";

import "./About.css";

const About: React.FC = () => (
  <div className="About">
    <MeComponent />
    <CvComponent title="Education" cvComponents={educations} />
    <CvComponent title="Work Experience" cvComponents={jobs} />
  </div>
);

export default About;

/* const compenteces = [
  {
    title: ".Net core",
    fromYear: "2018",
    toYear: "2019",
    organization: "",
    tags: []
  },
  {
    title: "Node.js",
    fromYear: "2018",
    toYear: "2019",
    organization: "",
    tags: []
  },
  {
    title: "DevOps",
    fromYear: "2018",
    toYear: "2019",
    organization: "",
    tags: []
  }
]; */

const educations = [
  {
    title: "Exchange Semester",
    fromYear: "2016",
    toYear: "2016",
    organization: "Nanyang Technological University, Singapore",
    description: "The Spring semester 2017 was spent abroad in Singapore",
    tags: []
  },
  {
    title: "M.Sc. in Engineering Mathmatics",
    fromYear: "2013",
    toYear: "2019",
    organization: "Lunds Tekniska Högskola",
    description:
      "A M.Sc. Engineering in Engineering Mathematics with a master in computer science.",
    tags: []
  },
  {
    title: "Exchange Year",
    fromYear: "2010",
    toYear: "2011",
    organization: "Foothills Academy, Arizona, USA",
    description:
      "I spent one year abroad in the U.S. in a high school exchange program",
    tags: []
  }
];

const jobs = [
  {
    title: "Project Fora AB",
    fromYear: "2019",
    toYear: "2019",
    organization: "Valtech SE",
    description:
      "Digital services aimed towerds user to handle their pension funds were designed and developed",
    tags: ["Node.js", "TypeScript", "Azure DevOps", "Azure"]
  },
  {
    title: "Project Forex Bank",
    fromYear: "2018",
    toYear: "2019",
    organization: "Valtech SE",
    description: "API to new digital services in a microservice infrastructure",
    tags: [
      ".Net Core",
      "MongoDB",
      "TypeScript",
      "Docker",
      "Kubernetes",
      "OpenId Connect",
      "Azure"
    ]
  },
  {
    title: "Trainee Program",
    fromYear: "2018",
    toYear: "2018",
    organization: "Valtech SE",
    description:
      "A three month introduction to modern web and system technolgies",
    tags: [".Net Core", "Agile", "React", "Docker", "Azure"]
  },
  {
    title: "Master's Thesis",
    fromYear: "2018",
    toYear: "2019",
    organization: "Silvaco Inc.",
    description: "Parallelization of Computational Algorithms",
    tags: ["C++", "Multithreading", "Computational Geometry"]
  },
  {
    title: "Summer Intern",
    fromYear: "2015",
    toYear: "2018",
    organization: "SAAB AB",
    description:
      "Three summers between 2015 and 2018, I worked as a summer intern at SAAB AB, Developing software in MATLAB and javascript.",

    tags: ["MATLAB"]
  }
];
