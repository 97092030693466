import * as types from "../actions/actionTypes";

export const financialsReducer = (
  state: any = { financials: null },
  action: any
): any => {
  switch (action.type) {
    case types.GET_FINANCIALS_SUCCESS:
      return {
        ...state,
        financials: action.financials,
        currency: action.currency,
        numShares: action.numShares,
        financialsToPriceCurrencyMultiple:
          action.financialsToPriceCurrencyMultiple
      };
    case types.ADD_FINANCIALS_ROW:
      return { ...state, addingFinancialsRow: true };
    case types.SEND_FINANCIALS_ROW:
      return { ...state, addingFinancialsRow: false };
    default:
      return state;
  }
};
