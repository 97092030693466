/* export const coronaStatus = {
  country: "China",
  stats: [
    {
      date: "193993",
      totalCases: 79257,
      newCases: 433,
      totalDeaths: 2835,
      newDeaths: 47,
      activeCases: 37121,
      totalRecovered: 39301,
      seriousCritical: 7664
    }
  ]
}; */

export const coronaStatus = [
  {
    countryName: "China",
    totalCases: 79257,
    newCases: 433,
    totalDeaths: 2835,
    newDeaths: 47,
    activeCases: 37121,
    totalRecovered: 39301,
    seriousCritical: 7664
  },
  {
    countryName: "S. Korea",
    totalCases: 3150,
    newCases: 813,
    totalDeaths: 17,
    newDeaths: 1,
    activeCases: 3109,
    totalRecovered: 24,
    seriousCritical: 10
  },
  {
    countryName: "Italy",
    totalCases: 889,
    newCases: 0,
    totalDeaths: 21,
    newDeaths: 0,
    activeCases: 822,
    totalRecovered: 46,
    seriousCritical: 64
  },
  {
    countryName: "Diamond Princess",
    totalCases: 705,
    newCases: 0,
    totalDeaths: 6,
    newDeaths: 0,
    activeCases: 689,
    totalRecovered: 10,
    seriousCritical: 36
  },
  {
    countryName: "Iran",
    totalCases: 593,
    newCases: 205,
    totalDeaths: 43,
    newDeaths: 9,
    activeCases: 427,
    totalRecovered: 123,
    seriousCritical: 0
  },
  {
    countryName: "Japan",
    totalCases: 241,
    newCases: 8,
    totalDeaths: 5,
    newDeaths: 0,
    activeCases: 204,
    totalRecovered: 32,
    seriousCritical: 20
  },
  {
    countryName: "Singapore",
    totalCases: 102,
    newCases: 4,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 30,
    totalRecovered: 72,
    seriousCritical: 7
  },
  {
    countryName: "Hong Kong",
    totalCases: 93,
    newCases: 0,
    totalDeaths: 2,
    newDeaths: 0,
    activeCases: 61,
    totalRecovered: 30,
    seriousCritical: 6
  },
  {
    countryName: "Germany",
    totalCases: 79,
    newCases: 5,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 63,
    totalRecovered: 16,
    seriousCritical: 2
  },
  {
    countryName: "France",
    totalCases: 73,
    newCases: 16,
    totalDeaths: 2,
    newDeaths: 0,
    activeCases: 59,
    totalRecovered: 12,
    seriousCritical: 1
  },
  {
    countryName: "USA",
    totalCases: 66,
    newCases: 3,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 59,
    totalRecovered: 7,
    seriousCritical: 1
  },
  {
    countryName: "Spain",
    totalCases: 50,
    newCases: 17,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 48,
    totalRecovered: 2,
    seriousCritical: 2
  },
  {
    countryName: "Kuwait",
    totalCases: 45,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 45,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Thailand",
    totalCases: 42,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 14,
    totalRecovered: 28,
    seriousCritical: 2
  },
  {
    countryName: "Bahrain",
    totalCases: 41,
    newCases: 3,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 41,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Taiwan",
    totalCases: 39,
    newCases: 5,
    totalDeaths: 1,
    newDeaths: 0,
    activeCases: 33,
    totalRecovered: 5,
    seriousCritical: 1
  },
  {
    countryName: "Malaysia",
    totalCases: 25,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 5,
    totalRecovered: 20,
    seriousCritical: 0
  },
  {
    countryName: "Australia",
    totalCases: 25,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 10,
    totalRecovered: 15,
    seriousCritical: 0
  },
  {
    countryName: "U.K.",
    totalCases: 23,
    newCases: 3,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 15,
    totalRecovered: 8,
    seriousCritical: 0
  },
  {
    countryName: "U.A.E.",
    totalCases: 19,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 14,
    totalRecovered: 5,
    seriousCritical: 2
  },
  {
    countryName: "Canada",
    totalCases: 16,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 13,
    totalRecovered: 3,
    seriousCritical: 0
  },
  {
    countryName: "Vietnam",
    totalCases: 16,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 16,
    seriousCritical: 0
  },
  {
    countryName: "Switzerland",
    totalCases: 15,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 15,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Sweden",
    totalCases: 12,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 12,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Macao",
    totalCases: 10,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 4,
    totalRecovered: 6,
    seriousCritical: 0
  },
  {
    countryName: "Austria",
    totalCases: 9,
    newCases: 2,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 9,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Iraq",
    totalCases: 8,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 8,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Norway",
    totalCases: 7,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 7,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Israel",
    totalCases: 7,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 6,
    totalRecovered: 1,
    seriousCritical: 0
  },
  {
    countryName: "Oman",
    totalCases: 6,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 5,
    totalRecovered: 1,
    seriousCritical: 0
  },
  {
    countryName: "Netherlands",
    totalCases: 6,
    newCases: 4,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 6,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Croatia",
    totalCases: 5,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 5,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Greece",
    totalCases: 4,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 4,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Pakistan",
    totalCases: 4,
    newCases: 2,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 4,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Lebanon",
    totalCases: 4,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 4,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Denmark",
    totalCases: 3,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 3,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Mexico",
    totalCases: 3,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 3,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Romania",
    totalCases: 3,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 2,
    totalRecovered: 1,
    seriousCritical: 0
  },
  {
    countryName: "Georgia",
    totalCases: 3,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 3,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Philippines",
    totalCases: 3,
    newCases: 0,
    totalDeaths: 1,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 2,
    seriousCritical: 0
  },
  {
    countryName: "Finland",
    totalCases: 3,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 2,
    totalRecovered: 1,
    seriousCritical: 0
  },
  {
    countryName: "India",
    totalCases: 3,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 3,
    seriousCritical: 0
  },
  {
    countryName: "Russia",
    totalCases: 2,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 2,
    seriousCritical: 0
  },
  {
    countryName: "Belarus",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Brazil",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Ecuador",
    totalCases: 1,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Estonia",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Lithuania",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Monaco",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "New Zealand",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Qatar",
    totalCases: 1,
    newCases: 1,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "San Marino",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Algeria",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Afghanistan",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Azerbaijan",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Belgium",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 1,
    seriousCritical: 0
  },
  {
    countryName: "Cambodia",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 1,
    seriousCritical: 0
  },
  {
    countryName: "Egypt",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 1,
    seriousCritical: 0
  },
  {
    countryName: "Iceland",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "North Macedonia",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Nepal",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 1,
    seriousCritical: 0
  },
  {
    countryName: "Nigeria",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 1,
    totalRecovered: 0,
    seriousCritical: 0
  },
  {
    countryName: "Sri Lanka",
    totalCases: 1,
    newCases: 0,
    totalDeaths: 0,
    newDeaths: 0,
    activeCases: 0,
    totalRecovered: 1,
    seriousCritical: 0
  }
];
