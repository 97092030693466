import React from "react";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Tooltip,
  Area,
  Legend,
  Line,
  Bar
} from "recharts";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  makeStyles
} from "@material-ui/core";
import { BrowserView } from "react-device-detect";

let max = 0;
let min = 0;
let mid = 0;
type Props = {
  option: any;
  state: any;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },

  formGroup: {
    display: "flex",
    overflow: "auto",
    alignContent: "center",
    justifyContent: "center"
  }
}));

export default function DailyGraph({ timeline }: any): any {
  const classes = useStyles();
  const [showingTotalInf, setTotalInf] = React.useState(true);
  const [showingTotalRec, setTotalRec] = React.useState(false);
  const [showingActiveInf, setActiveInf] = React.useState(false);

  min = timeline.reduce(
    (tot: any, curr: any) => (tot < curr.totalCases ? tot : curr.totalCases),
    1000000000
  );
  max = timeline.reduce(
    (tot: any, curr: any) => (tot > curr.totalCases ? tot : curr.totalCases),
    0
  );
  mid = Math.floor((min + max) / 2);

  let prevObj = {
    totalCases: 0,
    activeCases: 0,
    totalRecovered: 0,
    date: 0,
    totalDeaths: 0
  };
  const oneDayChangeTimeline = timeline
    .map((t: any) => {
      const obj = {
        totalInfected: t.totalCases ? t.totalCases - prevObj.totalCases : 0,
        activeInfected: t.activeCases ? t.activeCases - prevObj.activeCases : 0,
        totalRecovered: t.totalRecovered
          ? t.totalRecovered - prevObj.totalRecovered
          : 0,
        date: t.date,
        totalDeaths: t.totalDeaths - prevObj.totalDeaths
      };
      prevObj = t;
      return obj;
    })
    .filter((t: any) => showingTotalInf || t.activeInfected);
  return (
    <React.Fragment>
      <BrowserView>
        <FormGroup row className={classes.formGroup}>
          <FormControlLabel
            control={
              <Switch
                checked={showingTotalInf}
                onChange={() => {
                  setTotalInf(!showingTotalInf);
                }}
              />
            }
            label="Total Infected"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showingActiveInf}
                onChange={() => setActiveInf(!showingActiveInf)}
              />
            }
            label="Active Infected"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showingTotalRec}
                onChange={() => setTotalRec(!showingTotalRec)}
              />
            }
            label="Total Recovered"
          />
        </FormGroup>
      </BrowserView>
      <ResponsiveContainer>
        <ComposedChart
          width={750}
          height={525}
          data={oneDayChangeTimeline}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <XAxis
            dataKey="date"
            //tickFormatter={unixTime => moment(unixTime).format("MMM YY")}
            // interval={timeline ? Math.floor(timeline.length / 10) : 1}
          />
          <YAxis
            //domain={[0, Math.floor(max + mid * 0.05)]}
            tickFormatter={val =>
              val !== 0 ? Math.round(val / 1000) + " K" : 0
            }
          />{" "}
          />
          <Tooltip />
          <Legend />
          {showingTotalInf ? (
            <Bar dataKey="totalInfected" stroke="#800000" fill={red[100]} />
          ) : (
            undefined
          )}
          {showingTotalRec ? (
            <Bar
              dataKey="totalRecovered"
              stroke={green[900]}
              fill={green[800]}
            />
          ) : (
            undefined
          )}
          {showingActiveInf ? (
            <Bar dataKey="activeInfected" stroke={red[900]} fill={red[400]} />
          ) : (
            undefined
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
