import { combineEpics } from "redux-observable";

import financialEpic from "./financialEpic";
import pricesEpic from "./pricesEpic";
import gameEpic from "./gameEpic";
import coronaEpic from "./coronaEpic";

const epics = combineEpics(
  ...financialEpic,
  ...pricesEpic,
  ...gameEpic,
  ...coronaEpic
);

export default epics;
