import React, { useEffect } from "react";
import { SimpleTable as FinancialsTable } from "./table/Table";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";

import {
  Grid,
  Paper,
  Button,
  Select,
  CssBaseline,
  Container,
  TextField,
  Icon
} from "@material-ui/core";
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  //appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 100
  },
  button: {
    marginTop: theme.spacing(3)
    //maxWidth: "500px"
  }
}));

export default function StockAdmin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const financials = useSelector((state: any) => state.financials);

  useEffect(() => window.scrollTo(0, 0));

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={8} alignItems="center">
          <Grid item xs={12} md={8} lg={12}>
            <Paper className={classes.paper}>
              <Select
                native
                color="primary"
                defaultValue="Select"
                onChange={(event: any) => {
                  dispatch(actions.getFinancials(event.target.value));
                }}
              >
                <option value="Select">Select</option>
                {stockList.map(s => (
                  <option key={s.value} value={s.value}>
                    {s.label}
                  </option>
                ))}
              </Select>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {FinancialsTable(financials.financials)}
            </Paper>
          </Grid>
          {financials && financials.addingFinancialsRow ? (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <form className={classes.root} noValidate autoComplete="off">
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-basic"
                        label="Period"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-basic"
                        label="Revenue"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-basic"
                        label="Ebitda"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-basic"
                        label="AdjEbit"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-basic"
                        label="Ebit"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-basic"
                        label="Profit"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-basic"
                        label="Eps"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-basic"
                        label="NetDebt"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </form>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(actions.sendFinancialsRow())}
                  className={classes.button}
                  endIcon={<Icon>send</Icon>}
                >
                  Send
                </Button>
              </Paper>
            </Grid>
          ) : (
            undefined
          )}
        </Grid>

        {!financials || !financials.addingFinancialsRow ? (
          <Button
            fullWidth
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => dispatch(actions.addFinancialsRow())}
          >
            Add Period
          </Button>
        ) : (
          undefined
        )}
      </Container>

      {/*  <Grid item xs={12}>
        <Paper className={classes.paper}>{FinancialsTable(financials)}</Paper>
      </Grid> */}
    </div>
  );
}

const stockList = [
  {
    value: "TTWO",
    label: "Take-Two Interactive Software Inc",
    yahooTick: "TTWO"
  },
  {
    value: "EMBRAC-B",
    label: "Embracer Group B",
    yahooTick: "EMBRAC-B.ST",
    games: ""
  },
  { value: "SF", label: "Stillfront Group", yahooTick: "SF.ST" },
  { value: "SOBI", label: "Swedish Orphan Biovitrum", yahooTick: "SOBI.ST" },
  { value: "KIND-SDB", label: "Kindred Group", yahooTick: "KIND-SDB.ST" },
  { value: "BETCO", label: "Better Collective", yahooTick: "BETCO.ST" },
  { value: "PDX", label: "Paradox Interactive", yahooTick: "PDX.ST" },
  { value: "UBI", label: "Ubisoft Entertainment", yahooTick: "UBI.PA" },
  { value: "ATVI", label: "Activision Blizzard", yahooTick: "ATVI" },
  { value: "EA", label: "Electronic Arts", yahooTick: "EA" }
];

/* <Grid item xs={12} md={8} lg={9}>
            <Paper className={fixedHeightPaper}>
              {FinancialsTable(financials)}
            </Paper>
          </Grid>
         
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
              {FinancialsTable(financials)}
            </Paper>
          </Grid> */
