import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Header: React.FC = () => (
  <div className="Header">
    <div className="HeaderComponent">
      <Link to="/" style={{ textDecoration: "none" }}>
        <p>gustavkullberg.com</p>
      </Link>
    </div>
    <div className="HeaderComponent">
      <Link to="/stocks" style={{ textDecoration: "none" }}>
        <p>Stocks</p>
      </Link>
    </div>
    <div className="HeaderComponent">
      <Link to="/corona" style={{ textDecoration: "none" }}>
        <p>Corona Watch</p>
      </Link>
    </div>
    <div className="HeaderComponent">
      <Link to="/about" style={{ textDecoration: "none" }}>
        <p>About</p>
      </Link>
    </div>
  </div>
);

export default Header;
