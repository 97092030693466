import { createStore, applyMiddleware } from "redux";
import createRootReducer, { RootState } from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import epics from "./epics";
import { Action } from "typesafe-actions";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware<Action, Action, RootState>();

export function configureStore(initialState: object) {
  const store = createStore(
    createRootReducer(history),
    composeWithDevTools(
      applyMiddleware(epicMiddleware, routerMiddleware(history))
    )
  );
  epicMiddleware.run(epics);
  return store;
}
