import React from "react";
import ComponentRow, { Row } from "../componentRow/ComponentRow";
import "./CvComponent.css";

type Props = {
  title?: string;
  cvComponents: Row[];
};

const CvComponent: React.FC<Props> = ({ title, cvComponents }: Props) => (
  <div className="CvComponent">
    <h1>{title}</h1>
    {cvComponents.map(c => {
      return (
        <div className="bigRow">
          <div className="yearPerRow">
            <p>
              {c.fromYear} <br />- <br />
              {c.toYear}
            </p>
          </div>
          <ComponentRow
            fromYear={c.fromYear}
            toYear={c.toYear}
            title={c.title}
            organization={c.organization}
            description={c.description}
            tags={c.tags}
          />
        </div>
      );
    })}
  </div>
);

export default CvComponent;
