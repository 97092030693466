import React from "react";
import "./MeComponent.css";
import "../cvComponent/CvComponent.css";

const image = require("../../../images/me.png");

type Props = {
  title?: string;
};

const MeComponent: React.FC<Props> = ({ title }: Props) => (
  <div className="MeContainer">
    <div className="MeComponent">
      <div className="imageContainer">
        <img src={image} width="500px" alt="blabla" />
      </div>
      <div className="textContainer">
        <p>
          Hello!
          <br /> <br />
          My name is Gustav Kullberg
          <br /> <br />
          Welcome to my website.
        </p>
      </div>
    </div>
  </div>
);

export default MeComponent;
