import * as types from "../actions/actionTypes";

export const gamesReducer = (state: any = "", action: any): any => {
  switch (action.type) {
    case types.GET_GAME_SUCCESS: {
      return { ...state, ...action.game };
    }
    default:
      return state;
  }
};
