import { Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Action } from "typesafe-actions";
import { RootState } from "../reducers";
import {
  GET_GAME,
  GET_GAME_SUCCESS,
  INIT_CORONA_STATUS,
  GET_CORONA_STATUS_SUCCESS,
  GET_GLOBAL_CORONA_TIMELINE,
  GET_GLOBAL_CORONA_TIMELINE_SUCCESS
} from "../actions/actionTypes";
import { getConcurrentPlayersByGameId } from "../../api/ConcurrentPlayers";
import { getCoronaStatus } from "../../api/CoronaStatus";
import { getCoronaTimeline } from "../../api/CoronaTimelines";

const coronaEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    ofType(INIT_CORONA_STATUS),
    mergeMap((action: any) => {
      return from(
        getCoronaStatus().then(async cs => {
          return {
            type: GET_CORONA_STATUS_SUCCESS,
            coronaStatus: cs.coronaStatus
          };
        })
      );
    })
  );

const getGlobalCoronaEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    ofType(GET_GLOBAL_CORONA_TIMELINE),
    mergeMap((action: any) => {
      return from(
        getCoronaTimeline().then(async cs => {
          return {
            type: GET_GLOBAL_CORONA_TIMELINE_SUCCESS,
            globalTimeline: cs.coronaTimelines.global,
            countriesTimelines: cs.coronaTimelines.countriesTimelines
          };
        })
      );
    })
  );

export default [coronaEpic, getGlobalCoronaEpic];
