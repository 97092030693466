import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import clsx from "clsx";

import {
  Grid,
  Paper,
  CssBaseline,
  Container,
  Drawer,
  List,
  IconButton,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Typography,
  TextField
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PriceChart from "./PriceChart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";
import GamesView from "./GamesView";
import { financialView } from "./FinancialsView";
import { DashboardView } from "./DashboardView";
import { stockList } from "./StockList";
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  //appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 800
  },
  smallFixedHeight: {
    height: 400,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(3)
    //maxWidth: "500px"
  },
  formGroup: {
    display: "flex",
    overflow: "auto",
    alignContent: "center",
    justifyContent: "center"
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  card: {
    height: 120,
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "6.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  }
}));

const vals = ["Market Cap", "EV", "EV/EBIT", "Adjusted EV/EBIT", "P/E"];

export default function StockAnalysis() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { financials } = useSelector((state: any) => state);
  const prices = useSelector((state: any) => state.prices);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const smallFixedHeightPaper = clsx(classes.paper, classes.smallFixedHeight);
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpenClose = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const [selectedStock, selectStock] = React.useState("TTWO");
  const handleSelectedStock = (id: string) => {
    selectStock(id);
  };

  const [view, selectView] = React.useState("DASHBOARD");

  const [showingRevenue, toggleShowRevenue] = React.useState(true);
  const handleRevenue = () => {
    showingRevenue ? toggleShowRevenue(false) : toggleShowRevenue(true);
  };
  const [showingEbitda, toggleShowEbitda] = React.useState(true);
  const handleEbitda = () => {
    showingEbitda ? toggleShowEbitda(false) : toggleShowEbitda(true);
  };
  const [showingEbit, toggleShowEbit] = React.useState(true);
  const handleEbit = () => {
    showingEbit ? toggleShowEbit(false) : toggleShowEbit(true);
  };
  const [showingProfit, toggleShowProfit] = React.useState(true);
  const handleProfit = () => {
    showingProfit ? toggleShowProfit(false) : toggleShowProfit(true);
  };
  const [showingEps, toggleShowEps] = React.useState(true);
  const handleEps = () => {
    showingEps ? toggleShowEps(false) : toggleShowEps(true);
  };
  const [showingAdjEbit, toggleAdjEbit] = React.useState(true);
  const handleAdjEbit = () => {
    showingAdjEbit ? toggleAdjEbit(false) : toggleAdjEbit(true);
  };
  const [showingAdjPe, toggleAdjPe] = React.useState(true);
  const handleAdjPe = () => {
    showingAdjPe ? toggleAdjPe(false) : toggleAdjPe(true);
  };

  const [stockFilter, setStockFilter] = React.useState("");
  const handleStockFilter = (value: any) => {
    setStockFilter(value);
  };
  const [dates, setDates] = React.useState(["1971-01-01", " 9999-01-01"]);

  const handleDates = (event: any, newValues: any): any => {
    setDates(newValues);
  };

  useEffect(() => {
    dispatch(actions.getFinancials(selectedStock));
    dispatch(actions.getPrices(selectedStock));
  }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerOpenClose}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem button onClick={() => selectView("DASHBOARD")}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button onClick={() => selectView("PRICES")}>
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary="Prices" />
            </ListItem>
            <ListItem button onClick={() => selectView("FINANCIALS")}>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Financials" />
            </ListItem>
            {/*  <ListItem button onClick={() => selectView("CASHFLOW")}>
              <ListItemIcon>
                <VideogameAssetIcon />
              </ListItemIcon>
              <ListItemText primary="Games" />
            </ListItem> */}
          </div>
        </List>
        <Divider />
        {/*  <ListSubheader>Stocks</ListSubheader> */}
        <List>
          <div>
            <ListItem>
              <TextField
                id="standard-basic"
                label="Search"
                onChange={event => handleStockFilter(event.target.value)}
              />
            </ListItem>
            {stockList.map(s => {
              if (
                s.value.toLowerCase().includes(stockFilter.toLowerCase()) ||
                s.label.toLowerCase().includes(stockFilter.toLowerCase())
              ) {
                return (
                  <ListItem
                    button
                    selected={selectedStock === s.value}
                    onClick={() => {
                      handleSelectedStock(s.value);
                      dispatch(actions.getFinancials(s.value));
                      dispatch(actions.getPrices(s.value));
                    }}
                  >
                    <ListItemText primary={s.label} />
                  </ListItem>
                );
              }
            })}
          </div>
        </List>
      </Drawer>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={8} alignItems="center">
          <Grid item xs={12} md={8} lg={12}>
            <Container className={classes.cardGrid} maxWidth="lg">
              <Grid container spacing={1}>
                {vals.map(card => {
                  if (
                    !prices ||
                    !prices.prices ||
                    !financials ||
                    !financials.financials ||
                    view !== "DASHBOARD"
                  )
                    return undefined;
                  const marketCap = Math.round(
                    (prices.prices[prices.prices.length - 1].Close *
                      financials.numShares) /
                      1000000 /
                      financials.financialsToPriceCurrencyMultiple
                  );
                  const ev =
                    marketCap +
                    financials.financials[financials.financials.length - 1]
                      .NetDebt;

                  const l1 = financials.financials.length;
                  let rollingEbit = 0;
                  for (let i = l1 - 4; i < l1; i++) {
                    rollingEbit += financials.financials[i].Ebit;
                  }
                  let rollingEarnings = 0;
                  for (let i = l1 - 4; i < l1; i++) {
                    rollingEarnings += financials.financials[i].Profit;
                  }
                  let rollingAdjEbit = 0;
                  for (let i = l1 - 4; i < l1; i++) {
                    rollingAdjEbit += financials.financials[i]
                      ? financials.financials[i].AdjEbit
                      : 0;
                  }

                  if (card === "Adjusted EV/EBIT" && !rollingAdjEbit)
                    return null;

                  return (
                    <Grid item key={card} xs={12} sm={3} md={3}>
                      <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                          <Typography gutterBottom variant="h6" component="h2">
                            {card}
                          </Typography>
                          <Typography gutterBottom variant="h3" component="h2">
                            {card === "Market Cap" ? marketCap : undefined}
                            {card === "EV/EBIT"
                              ? Math.round(ev / rollingEbit)
                              : undefined}
                            {card === "P/E"
                              ? Math.round(marketCap / rollingEarnings)
                              : undefined}
                            {card === "EV" ? ev : undefined}
                            {card === "Adjusted EV/EBIT"
                              ? Math.round(ev / rollingAdjEbit)
                              : undefined}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>

            {getStuff({
              view,
              financials,
              classes,
              showingRevenue,
              handleRevenue,
              showingEbitda,
              handleEbitda,
              showingEbit,
              handleEbit,
              showingProfit,
              handleProfit,
              showingEps,
              handleEps,
              prices,
              fixedHeightPaper,
              smallFixedHeightPaper,
              handleDates,
              dates,
              showingAdjEbit,
              handleAdjEbit
            })}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const getStuff = ({
  view,
  financials,
  classes,
  showingRevenue,
  handleRevenue,
  showingEbitda,
  handleEbitda,
  showingEbit,
  handleEbit,
  showingProfit,
  handleProfit,
  showingEps,
  handleEps,
  prices,
  fixedHeightPaper,
  smallFixedHeightPaper,
  handleDates,
  dates,
  showingAdjEbit,
  handleAdjEbit
}: any) => {
  switch (view) {
    case "DASHBOARD": {
      return DashboardView({
        view,
        financials,
        classes,
        showingRevenue,
        handleRevenue,
        showingEbitda,
        handleEbitda,
        showingEbit,
        handleEbit,
        showingProfit,
        handleProfit,
        showingEps,
        handleEps,
        showingAdjEbit,
        handleAdjEbit,
        prices,
        smallFixedHeightPaper,
        handleDates,
        dates
      });
    }
    case "FINANCIALS": {
      return (
        <Paper className={fixedHeightPaper}>
          {financialView({
            view,
            financials,
            classes,
            showingRevenue,
            handleRevenue,
            showingEbitda,
            handleEbitda,
            showingEbit,
            handleEbit,
            showingProfit,
            handleProfit,
            showingEps,
            showingAdjEbit,
            handleAdjEbit,
            handleEps,
            prices
          })}
        </Paper>
      );
    }
    case "PRICES": {
      return (
        <Paper className={fixedHeightPaper}>
          <PriceChart {...{ prices }} />
        </Paper>
      );
    }
    /* case "CASHFLOW": {
      return (
        <Paper className={fixedHeightPaper}>
          <CashFlow />
        </Paper>
      );
    } */
    case "GAMES":
      return (
        <Paper className={fixedHeightPaper}>
          <GamesView {...{ gameStats: "1" }} />{" "}
        </Paper>
      );
  }
};
