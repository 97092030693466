import React from "react";
import "./ComponentRow.css";

export type Row = {
  fromYear: string;
  toYear: string;
  title: string;
  organization: string;
  description?: string;
  tags: string[];
};

type Props = {
  fromYear: string;
  toYear: string;
  title: string;
  organization: string;
  description?: string;
  tags: string[];
};

const ComponentRow: React.FC<Props> = ({
  fromYear,
  toYear,
  title,
  organization,
  description,
  tags
}: Props) => (
  <div className="ComponentRow">
    <h3>
      {title} <br /> {organization}
    </h3>
    <p>{description}</p>
    <div className="competenceTags">
      {tags.map(t => {
        return (
          <div className="competenceTag">
            <p>{t}</p>
          </div>
        );
      })}
    </div>

    {/*  {tags.reduce((a, b) => (
      <div>a + b</div>
    ))} */}
  </div>
);

export default ComponentRow;
