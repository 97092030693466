export const GET_FINANCIALS = "GET_FINANCIALS";
export const GET_FINANCIALS_SUCCESS = "GET_FINANCIALS_SUCCESS";
export const ADD_FINANCIALS_ROW = "ADD_FINANCIALS_ROW";
export const SEND_FINANCIALS_ROW = "SEND_FINANCIALS_ROW";
export const TOGGLE_SHOW_REVENUE = "TOGGLE_SHOW_REVENUE";

export const GET_PRICES = "GET_PRICES";
export const GET_PRICES_SUCCESS = "GET_PRICES_SUCCESS";

export const GET_GAME = "GET_GAME";
export const GET_GAME_SUCCESS = "GET_GAME_SUCCESS";
export const GET_GAME_FAILED = "GET_GAME_FAILED";

export const INIT_CORONA_STATUS = "INIT_CORONA_STATUS";
export const GET_CORONA_STATUS_SUCCESS = "GET_CORONA_STATUS_SUCCESS";
export const SET_CORONA_STATUS = "SET_CORONA_STATUS";
export const GET_GLOBAL_CORONA_TIMELINE = "GET_GLOBAL_CORONA_TIMELINE";
export const GET_GLOBAL_CORONA_TIMELINE_SUCCESS =
  "GET_GLOBAL_CORONA_TIMELINE_SUCCESS";
