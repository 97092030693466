import React from "react";
import Chart from "./Chart";
import moment from "moment";
import PriceChart from "./PriceChart";

import {
  FormGroup,
  Switch,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";

export const DashboardView = ({
  financials,
  classes,
  showingRevenue,
  handleRevenue,
  showingEbitda,
  handleEbitda,
  showingEbit,
  handleEbit,
  showingProfit,
  handleProfit,
  showingEps,
  handleEps,
  prices,
  smallFixedHeightPaper,
  handleDates,
  dates,
  showingAdjEbit,
  handleAdjEbit
}: any) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={smallFixedHeightPaper}>
            <PriceChart {...{ prices }} />
            {/*             <Slider
          value={dates}
          onChange={handleDates}
          valueLabelDisplay="auto"
          aria-labelledby="range-sli  der"
          getAriaValueText={() =>dates}
        /> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={smallFixedHeightPaper}>
            <Typography gutterBottom variant="h6" component="h2">
              Latest NAV
            </Typography>
            <Typography
              component="h2"
              variant="h2"
              gutterBottom
              style={{
                flex: 1
              }}
            >
              {prices.prices
                ? prices.prices[prices.prices.length - 1].Close
                : undefined}
            </Typography>

            <Typography gutterBottom variant="h6" component="h2">
              Change 1D
            </Typography>

            <Typography
              component="h2"
              variant="h2"
              style={{
                color:
                  prices.prices &&
                  prices.prices[prices.prices.length - 1].Close -
                    prices.prices[prices.prices.length - 2].Close >
                    0
                    ? "#66bb6a"
                    : "#ef5350",
                flex: 1
              }}
            >
              {prices.prices
                ? `${Math.round(
                    (prices.prices[prices.prices.length - 1].Close /
                      prices.prices[prices.prices.length - 2].Close -
                      1) *
                      100 *
                      100
                  ) / 100}%`
                : undefined}
            </Typography>
            <div>
              <Typography
                color="textSecondary"
                className={classes.depositContext}
              >
                on{" "}
                {prices.prices
                  ? moment(prices.prices[prices.prices.length - 1].Date).format(
                      "ll"
                    )
                  : ""}
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Paper className={smallFixedHeightPaper}>
        <FormGroup row className={classes.formGroup}>
          <FormControlLabel
            control={
              <Switch checked={showingRevenue} onChange={handleRevenue} />
            }
            label="Revenue"
          />
          <FormControlLabel
            control={<Switch checked={showingEbitda} onChange={handleEbitda} />}
            label="Ebitda"
          />
          {financials.financials && financials.financials[0].AdjEbit ? (
            <FormControlLabel
              control={
                <Switch checked={showingAdjEbit} onChange={handleAdjEbit} />
              }
              label="Adj. Ebit"
            />
          ) : (
            undefined
          )}
          <FormControlLabel
            control={<Switch checked={showingEbit} onChange={handleEbit} />}
            label="Ebit"
          />
          <FormControlLabel
            control={<Switch checked={showingProfit} onChange={handleProfit} />}
            label="Profit"
          />
          <FormControlLabel
            control={<Switch checked={showingEps} onChange={handleEps} />}
            label="EPS"
          />
        </FormGroup>

        <Chart
          {...{
            financials,
            showingRevenue,
            showingEbitda,
            showingEbit,
            showingProfit,
            showingEps,
            showingAdjEbit
          }}
        />
      </Paper>
    </React.Fragment>
  );
};
