export const stockList = [
  {
    value: "TTWO",
    label: "Take-Two Interactive Software Inc",
    yahooTick: "TTWO"
  },
  {
    value: "EMBRAC-B",
    label: "Embracer Group B",
    yahooTick: "EMBRAC-B.ST",
    games: ""
  },
  { value: "SF", label: "Stillfront Group", yahooTick: "SF.ST" },
  { value: "SOBI", label: "Swedish Orphan Biovitrum", yahooTick: "SOBI.ST" },
  { value: "KIND-SDB", label: "Kindred Group", yahooTick: "KIND-SDB.ST" },
  { value: "BETCO", label: "Better Collective", yahooTick: "BETCO.ST" },
  { value: "PDX", label: "Paradox Interactive", yahooTick: "PDX.ST" },
  { value: "UBI", label: "Ubisoft Entertainment", yahooTick: "UBI.PA" },
  { value: "ATVI", label: "Activision Blizzard", yahooTick: "ATVI" },
  { value: "EA", label: "Electronic Arts", yahooTick: "EA" }
];
