import React from "react";
import Chart from "./Chart";

import { FormGroup, Switch, FormControlLabel } from "@material-ui/core";

export const financialView = ({
  financials,
  classes,
  showingRevenue,
  handleRevenue,
  showingEbitda,
  handleEbitda,
  showingEbit,
  handleEbit,
  showingProfit,
  handleProfit,
  showingEps,
  handleEps,
  showingAdjEbit,
  handleAdjEbit
}: any) => (
  <React.Fragment>
    {" "}
    <FormGroup row className={classes.formGroup}>
      <FormControlLabel
        control={<Switch checked={showingRevenue} onChange={handleRevenue} />}
        label="Revenue"
      />
      <FormControlLabel
        control={<Switch checked={showingEbitda} onChange={handleEbitda} />}
        label="Ebitda"
      />
      <FormControlLabel
        control={<Switch checked={showingAdjEbit} onChange={handleAdjEbit} />}
        label="Adj. Ebit"
      />
      <FormControlLabel
        control={<Switch checked={showingEbit} onChange={handleEbit} />}
        label="Ebit"
      />
      <FormControlLabel
        control={<Switch checked={showingProfit} onChange={handleProfit} />}
        label="Profit"
      />
      <FormControlLabel
        control={<Switch checked={showingEps} onChange={handleEps} />}
        label="EPS"
      />
    </FormGroup>
    <Chart
      {...{
        financials,
        showingRevenue,
        showingEbitda,
        showingEbit,
        showingProfit,
        showingEps
      }}
    />
  </React.Fragment>
);
