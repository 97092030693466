import React from "react";
import background from "../../images/trollea.jpg";

const Body: React.FC = () => (
  <div className="Body">
    <div className="backgroundImage">
      <img src={background} className="image" alt="hehe" />
    </div>
  </div>
);

export default Body;
