import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 500,
    marginTop: theme.spacing(3)
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));
const headLabels = [
  { label: "Country", id: 0 },
  { label: "Total Infected", id: 1 },
  { label: "Total Death", id: 2 },
  { label: "Active Infected", id: 3 },
  { label: "Total Recovered", id: 4 },
  { label: "Active Critical", id: 5 }
];

export default function CasesSheet({
  coronaStatus,
  sortId,
  handleSort,
  setCountry
}: any) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Table
        className={classes.table}
        size="medium"
        aria-labelledby="tableTitle"
      >
        <TableHead>
          <TableRow>
            {headLabels.map(l => {
              return (
                <TableCell
                  sortDirection="asc"
                  align={l.id === 0 ? "left" : "right"}
                >
                  <TableSortLabel
                    active={sortId.id === l.id}
                    direction={sortId.direction}
                    onClick={() => handleSort(l.id)}
                  >
                    {l.label}
                    <span className={classes.visuallyHidden}>
                      {sortId.direction === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  </TableSortLabel>{" "}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {coronaStatus ? (
          <TableBody>
            <TableRow hover key={1} onClick={() => setCountry("World")}>
              <TableCell component="th" scope="row">
                World
              </TableCell>
              <TableCell align="right">
                {coronaStatus.reduce((t: any, c: any) => t + c.totalCases, 0)}
              </TableCell>
              <TableCell align="right">
                {coronaStatus.reduce((t: any, c: any) => t + c.totalDeaths, 0)}
              </TableCell>
              <TableCell align="right">
                {coronaStatus.reduce((t: any, c: any) => t + c.activeCases, 0)}
              </TableCell>
              <TableCell align="right">
                {coronaStatus.reduce(
                  (t: any, c: any) => t + c.totalRecovered,
                  0
                )}
              </TableCell>
              <TableCell align="right">
                {coronaStatus.reduce(
                  (t: any, c: any) => t + c.seriousCritical,
                  0
                )}
              </TableCell>
            </TableRow>

            {Object.values(coronaStatus).map((c: any) => {
              return (
                <TableRow
                  hover
                  key={c.name}
                  onClick={() => setCountry(c.countryName)}
                >
                  <TableCell component="th" scope="row">
                    {c.countryName}
                  </TableCell>
                  <TableCell align="right">{c.totalCases}</TableCell>
                  <TableCell align="right">{c.totalDeaths}</TableCell>
                  <TableCell align="right">{c.activeCases}</TableCell>
                  <TableCell align="right">{c.totalRecovered}</TableCell>
                  <TableCell align="right">{c.seriousCritical}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          undefined
        )}
      </Table>
    </React.Fragment>
  );
}
