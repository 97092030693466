import React from "react";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Tooltip,
  Area,
  Legend,
  Line
} from "recharts";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  makeStyles
} from "@material-ui/core";
import { BrowserView } from "react-device-detect";

let max = 0;
let min = 0;
let mid = 0;
type Props = {
  option: any;
  state: any;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },

  formGroup: {
    display: "flex",
    overflow: "auto",
    alignContent: "center",
    justifyContent: "center"
  }
}));

export default function CasesTimelineGraph({ timeline }: any): any {
  const classes = useStyles();
  const [showingTotalInf, setTotalInf] = React.useState(true);
  const [showingTotalRec, setTotalRec] = React.useState(false);
  const [showingActiveInf, setActiveInf] = React.useState(false);

  min = timeline.reduce(
    (tot: any, curr: any) => (tot < curr.totalCases ? tot : curr.totalCases),
    1000000000
  );
  max = timeline.reduce(
    (tot: any, curr: any) => (tot > curr.totalCases ? tot : curr.totalCases),
    0
  );
  mid = Math.floor((min + max) / 2);
  const formattedTimeline = timeline
    .map((t: any) => ({
      totalInfected: t.totalCases,
      activeInfected: t.activeCases,
      totalRecovered: t.totalRecovered,
      date: t.date,
      totalDeaths: t.totalDeaths
    }))
    .filter((t: any) => showingTotalInf || t.activeInfected);

  return (
    <React.Fragment>
      <BrowserView>
        <FormGroup row className={classes.formGroup}>
          <FormControlLabel
            control={
              <Switch
                checked={showingTotalInf}
                onChange={() => {
                  setTotalInf(!showingTotalInf);
                }}
              />
            }
            label="Total Infected"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showingActiveInf}
                onChange={() => setActiveInf(!showingActiveInf)}
              />
            }
            label="Active Infected"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showingTotalRec}
                onChange={() => setTotalRec(!showingTotalRec)}
              />
            }
            label="Total Recovered"
          />
        </FormGroup>
      </BrowserView>
      <ResponsiveContainer>
        <ComposedChart
          width={750}
          height={525}
          data={formattedTimeline}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <XAxis
            dataKey="date"
            //tickFormatter={unixTime => moment(unixTime).format("MMM YY")}
            // interval={timeline ? Math.floor(timeline.length / 10) : 1}
          />
          <YAxis
            //domain={[0, Math.floor(max + mid * 0.05)]}
            tickFormatter={val =>
              val !== 0 ? Math.round(val / 1000) + " K" : 0
            }
          />{" "}
          />
          <Tooltip />
          <Legend />
          {showingTotalInf ? (
            <Area dataKey="totalInfected" stroke="#800000" fill={red[100]} />
          ) : (
            undefined
          )}
          {showingTotalRec ? (
            <Area
              dataKey="totalRecovered"
              stroke={green[900]}
              stackId="1"
              fill={green[800]}
            />
          ) : (
            undefined
          )}
          {showingActiveInf ? (
            <Area
              type="monotone"
              stackId="1"
              dataKey="activeInfected"
              stroke={red[900]}
              fill={red[400]}
            />
          ) : (
            undefined
          )}
          {/* <Area
            dataKey="totalDeaths"
            stackId="1"
            stroke={red[900]}
            fill={red[800]}
          /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
