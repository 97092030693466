import React from "react";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Tooltip,
  Legend,
  Area
} from "recharts";
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import moment from "moment";

let max = 0;
let min = 0;
let mid = 0;
type Props = {
  option: any;
  state: any;
};
export default function PriceChart({ prices }: any): any {
  return (
    <React.Fragment>
      <ResponsiveContainer>
        <ComposedChart
          width={750}
          height={525}
          data={prices.prices}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <XAxis
            dataKey="Date"
            tickFormatter={unixTime => moment(unixTime).format("MMM YY")}
            interval={prices.prices ? Math.floor(prices.prices.length / 10) : 1}
          />
          <YAxis
            domain={[
              Math.floor(min - mid * 0.05),
              Math.floor(max + mid * 0.05)
            ]}
            tickFormatter={val => Math.round(val)}
          />{" "}
          />
          <Tooltip />
          <Legend />
          <Area dataKey="Close" stroke={getStroke(prices)} fill={getFill(prices)} />
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

const getFill = (prices:any) => {
  if(!prices.prices) return "black"
  const percentage = (prices.prices[prices.prices.length - 1].Close / prices.prices[0].Close - 1) * 100
  return percentage > 0 ? green[100]: red[100]
}
const getStroke = (prices:any)=> {
  if(!prices.prices) return "black"
  const percentage = (prices.prices[prices.prices.length - 1].Close / prices.prices[0].Close - 1) * 100
  return percentage > 0 ? "#29432A": "#800000"
}
