import React from "react";
import Body from "./components/body/Body";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

import About from "./components/about/About";
import StockAdmin from "./components/stockAdmin/StockAdmin";
import StockAnalysis from "./components/stockAnalysis/StockAnalysis";
import Corona from "./components/corona/Corona";

import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const darkTheme = createMuiTheme({
  palette: {
    type: "dark"
  }
});
const App: React.FC = () => (
  <ThemeProvider theme={darkTheme}>
    <BrowserRouter>
      <div className="wrapper">
        <div className="App">
          <Header />
          <Route exact path="/" component={Body} />
          <Route exact path="/index.html" component={Body} />
          <Route path="/about" component={About} />
          <Route path="/stocks" component={StockAnalysis} />
          <Route path="/corona" component={Corona} />
          <Route path="/stocksAdmin" component={StockAdmin} />
          <Route path="/stockAnalysis" component={StockAnalysis} />
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
