import { coronaReducer } from "./coronaReducer";
import { gamesReducer } from "./gamesReducer";
import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { financialsReducer } from "./financialsReducer";
import { pricesReducer } from "./pricesReducer";

const createRootReducer = (history: any) =>
  combineReducers({
    financials: financialsReducer,
    prices: pricesReducer,
    games: gamesReducer,
    corona: coronaReducer,
    router: connectRouter(history)
  });

export default createRootReducer;

export type RootState = {
  financials: any;
  router: RouterState;
};
