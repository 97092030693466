import * as types from "./actionTypes";

export const getFinancials = (id: string) => ({
  type: types.GET_FINANCIALS,
  id
});

export const addFinancialsRow = () => ({
  type: types.ADD_FINANCIALS_ROW
});

export const sendFinancialsRow = () => ({
  type: types.SEND_FINANCIALS_ROW
});

export const toggleShowRevenue = () => ({
  type: types.TOGGLE_SHOW_REVENUE
});

export const getPrices = (id: string) => ({
  type: types.GET_PRICES,
  id
});

export const getGame = (id: string) => ({
  type: types.GET_GAME,
  id
});

export const initCoronaStatus = (coronaStatus: any) => ({
  type: types.INIT_CORONA_STATUS,
  coronaStatus
});

export const setCoronaStatus = (coronaStatus: any) => ({
  type: types.SET_CORONA_STATUS,
  coronaStatus
});

export const getGlobalCoronaTimeline = () => ({
  type: types.GET_GLOBAL_CORONA_TIMELINE
});
/* export const getIndividualSuccess = (pno: string) => ({
  type: types.GET_INDIVIDUAL_SUCCESS,
  pno
});

export const getPensionProviders = (pno: string, contractId: string) => ({
  type: types.GET_PENSION_PROVIDERS,
  pno,
  contractId
});

export const pensionProviderSuccess = (pno: string, contractId: string) => ({
  type: types.PENSION_PROVIDERS_SUCCESS,
  pno,
  contractId
});

export const selectProviderForIndividual = (provider: string, pno: string) => ({
  type: types.SELECT_PROVIDER_FOR_INDIVIDUAL,
  provider,
  pno
});

export const createBamAllocation = (individual: Individual) => ({
  type: types.CREATE_BAM_ALLOCATION,
  individual
});

export const bamAllocationSuccess = (individual: Individual) => ({
  type: types.BAM_ALLOCATION_SUCCESS,
  individual
});

export const bamAllocationFailed = (individual: Individual) => ({
  type: types.BAM_ALLOCATION_FAILED,
  individual
}); */
