import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Tooltip,
  Legend
} from "recharts";

export const revenueColor = "#5E5E4B";
export const ebitdaColor = "#29432A";
export const ebitColor = "black";
export const adjEbitColor = "#006666";
export const profitColor = "#8A7E31";
export const epsColor = "#897F64";

export default function Chart({
  financials,
  showingRevenue,
  showingEbitda,
  showingEbit,
  showingProfit,
  showingEps,
  showingAdjEbit
}: any) {
  const theme = useTheme();
  return (
    <React.Fragment>
      <ResponsiveContainer>
        <ComposedChart
          data={financials.financials}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <XAxis dataKey="name" stroke={theme.palette.text.secondary} />
          <YAxis
            label={{
              value: "MSEK",
              angle: -90,
              position: "insideLeft",
              fill: "white"
            }}
            stroke={theme.palette.text.secondary}
          />
          <Tooltip itemStyle={{ color: "black" }} />
          <Legend />
          {showingRevenue === true ? (
            <Bar dataKey="Revenue" fill={revenueColor} />
          ) : (
            undefined
          )}
          {showingEbitda === true ? (
            <Bar dataKey="Ebitda" fill={ebitdaColor} />
          ) : (
            undefined
          )}
          {showingAdjEbit ? (
            <Bar dataKey="AdjEbit" fill={adjEbitColor} />
          ) : (
            undefined
          )}
          {showingEbit ? <Bar dataKey="Ebit" fill={ebitColor} /> : undefined}
          {showingProfit ? (
            <Bar dataKey="Profit" fill={profitColor} />
          ) : (
            undefined
          )}
          {showingEps ? <Bar dataKey="Eps" fill={epsColor} /> : undefined}
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
