import { getStockFinancialsById } from "./../../api/StockFinancials";
import { Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Action } from "typesafe-actions";
import { RootState } from "../reducers";
import { GET_FINANCIALS, GET_FINANCIALS_SUCCESS } from "../actions/actionTypes";

const financialEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    ofType(GET_FINANCIALS),
    mergeMap((action: any) => {
      return from(
        getStockFinancialsById(action.id).then(async financialsResponse => {
          return {
            type: GET_FINANCIALS_SUCCESS,
            financials: financialsResponse.financials,
            currency: financialsResponse.currency,
            numShares: financialsResponse.numShares,
            financialsToPriceCurrencyMultiple:
              financialsResponse.financialsToPriceCurrencyMultiple
          };
        })
      );
    })
  );

export default [financialEpic];
