import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Sphere
} from "react-simple-maps";
import { Countries } from "./Countries";
import red from "@material-ui/core/colors/red";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
  {
    markerOffset: -15,
    name: "Buenos Aires",
    coordinates: [-58.3816, -34.6037]
  },
  { markerOffset: -15, name: "La Paz", coordinates: [-68.1193, -16.4897] },
  { markerOffset: 25, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
  { markerOffset: 25, name: "Santiago", coordinates: [-70.6693, -33.4489] },
  { markerOffset: 25, name: "Bogota", coordinates: [-74.0721, 4.711] },
  { markerOffset: 25, name: "Quito", coordinates: [-78.4678, -0.1807] },
  { markerOffset: -15, name: "Georgetown", coordinates: [-58.1551, 6.8013] },
  { markerOffset: -15, name: "Asuncion", coordinates: [-57.5759, -25.2637] },
  { markerOffset: 25, name: "Paramaribo", coordinates: [-55.2038, 5.852] },
  { markerOffset: 25, name: "Montevideo", coordinates: [-56.1645, -34.9011] },
  { markerOffset: -15, name: "Caracas", coordinates: [-66.9036, 10.4806] },
  { markerOffset: -15, name: "Lima", coordinates: [-77.0428, -12.0464] },
  {
    markerOffset: -15,
    name: "LOL",
    coordinates: [77.0428, 12.0464]
  },
  { name: "FRANCKS", coordinates: [2.3072976, 48.8698679] }
];

const WorldMap = ({ coronaStatus, setCountry }: any) => {
  const worldCases = coronaStatus
    ? coronaStatus.reduce((t: any, c: any) => t + c.totalCases, 0)
    : 0;

  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 240
      }}
    >
      <Sphere id={"1"} fill="#919190" stroke="#E4E5E6" strokeWidth={0.5} />
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#EAEAEC"
              stroke="#D6D6DA"
            />
          ))
        }
      </Geographies>

      {coronaStatus
        ? Object.values(coronaStatus).map(
            ({ countryName, totalCases }: any) => {
              const country = Countries.find(c => c.name === countryName);
              if (!country) return;
              const x = country.geo.longitude;
              const y = country.geo.latitude;
              return (
                <Marker
                  key={countryName}
                  coordinates={[x, y]}
                  onClick={() => setCountry(countryName)}
                >
                  <circle
                    r={getRad(totalCases, worldCases)}
                    fill={red[300]}
                    stroke={red[300]}
                    strokeWidth={1}
                  />
                </Marker>
              );
            }
          )
        : undefined}
    </ComposableMap>
  );
};

const getRad = (totalCases: number, worldCases: number) => {
  if (totalCases / worldCases > 0.5) return 40;
  if (totalCases / worldCases > 0.2) return 30;
  if (totalCases / worldCases > 0.1) return 25;
  if (totalCases / worldCases > 0.05) return 20;
  if (totalCases / worldCases > 0.01) return 10;
  if (totalCases / worldCases > 0.001) return 5;
  return 2;
};

export default WorldMap;

/* export default WorldMap;
const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(["#ffedea", "#ff5233"]);

const MapChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    csv(`/vulnerability.csv`).then(data => {
      setData(data);
    });
  }, []);

  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 147
      }}
    >
      <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
      <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
      {data.length > 0 && (
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => {
              const d = data.find(s => s.ISO3 === geo.properties.ISO_A3);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d["2017"]) : "#F5F4F6"}
                />
              );
            })
          }
        </Geographies>
      )}
    </ComposableMap>
  );
};
*/
