import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 500,
    marginTop: theme.spacing(3)
  }
}));

export function SimpleTable(financials: any) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Table className={classes.table} size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Period</TableCell>
            <TableCell align="right">Revenue</TableCell>
            <TableCell align="right">Ebitda</TableCell>
            <TableCell align="right">Ebit</TableCell>
            <TableCell align="right">Profit</TableCell>
            <TableCell align="right">EPS</TableCell>
          </TableRow>
        </TableHead>
        {financials ? (
          <TableBody>
            {financials.map((f: any) => (
              <TableRow key={f.name}>
                <TableCell component="th" scope="row">
                  {f.name}
                </TableCell>
                <TableCell align="right">{f.Revenue}</TableCell>
                <TableCell align="right">{f.Ebitda}</TableCell>
                <TableCell align="right">{f.Ebit}</TableCell>
                <TableCell align="right">{f.Profit}</TableCell>
                <TableCell align="right">{f.Eps}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          undefined
        )}
      </Table>
    </React.Fragment>
  );
}
